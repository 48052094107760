<template>
  <div class="prefixs">
    
    <div class="outBj">
      <div class="headers">
      <div class="headerLeft">
        <img src="../assets/home/home-logo.svg" />
      </div>
      <div class="headerRight" @click="$store.commit('goService')">
        <img src="@/assets/home/homeService.svg" />
      </div>
    </div>
      <div class="mosh">
        <div class="msTit">
          <img src="@/assets/prefix/text-no1.webp" />
        </div>
        <div class="listOut">
          <div class="pcList" v-for="(item, index) in list"
          :key="index" @click="goLink(item)">
            <div class="yuanyu" :style="{'background-image': `url(${require(`@/assets/prefix/${'sudu0'+(index+1)}.webp`)})` }">
              {{ msList[index] }}ms
            </div>
            <div class="labels">
              线路{{ index+1 }}
            </div>
            <div class="urlDemo">{{ item.url }}</div>
            <!-- <vue-circle-counter :value="value" :max="max" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="headImg">
      <img src="@/assets/prefix/bj.webp" />
    </div> -->
    <!-- <div class="btnLine">
      <div class="btnDemo" @click="down">
        <div class="btnLeft">
          <img src="@/assets/prefix/android.webp" />
        </div>
        <div class="shu"></div>
        <div class="btnRight">
          <div>安卓版本</div>
          <div class="djxz">请点击下载</div>
        </div>
      </div>
      <div class="btnDemo" @click="downIos">
        <div class="btnLeft">
          <img src="@/assets/prefix/android_1.webp" />
        </div>
        <div class="shu"></div>
        <div class="btnRight">
          <div>IOS版本</div>
          <div class="djxz">请点击下载</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="listOut">
      <div
        class="listDemo"
        v-for="(item, index) in list"
        :key="index"
        :style="{ 'margin-bottom': index == list.length - 1 ? '0px' : '16px' }"
      >
        <div class="listLeft">
          <div class="leftLeft">{{ msList[index] }}ms</div>
          <div class="leftRight">
            {{ item.url }}
          </div>
        </div>
        <div class="three">
          <img src="@/assets/prefix/three.webp" />
        </div>
        <div class="inGame" @click="goLink(item)">进入游戏</div>
      </div>
    </div> -->
    
    <div class="bomImg">
      <img src="../assets/home/home-logo.svg" />
    </div>
    <div class="bqxx">© 2024 PANDA集团版权所有</div>
  </div>
</template>

<script>
//import VueCircleCounter from "vue-circle-counter";
export default {
  name: "prefixIndex",
  //components: { VueCircleCounter },
  data() {
    return {
      list: [
        {
          url: "https://pdvip1.top",
          link: "https://kj8qyk1p1mtu5hbn5kff.pdvip1.top",
        },
        {
          url: "https://pdvip2.top",
          link: "https://41i3lm8fbwyer4gtvb0j.pdvip2.top",
        },
        {
          url: "https://pdvip3.top",
          link: "https://wh1w0gvcdsbojmh6i4nc.pdvip3.top",
        },
        {
          url: "https://pdvip4.top",
          link: "https://8bs53f0ogivnsd2kxteh.pdvip4.top",
        },
      ],
      msList: [],
    };
  },
  mounted() {
    this.getMath();
    if (this.$route.query.code !== undefined) {
      console.log(this.$route.query.code);
      this.code = this.$route.query.code;
    }
  },
  methods: {
    getMath() {
      for (var i = 0; i < this.list.length; i++) {
        this.msList[i] = Math.floor(Math.random() * (30 - 10 + 1)) + 10;
      }
      this.msList = this.msList.sort((a, b) => a - b);
    },
    goLink(url) {
      if (this.code) {
        // window.location.href = url.link + "/#/?url=" +window.location.hostname +"&code=" +this.code;
        window.open(
          url.link +
            "/#/?url=" +
            window.location.hostname +
            "&code=" +
            this.code,
          "_blank"
        );
      } else {
        // window.location.href = url.link + "/#/?url=" + window.location.hostname;
        window.open(url.link + "/#/?url=" + window.location.hostname, "_blank");
      }
    },
    down() {
      const link = document.createElement("a");
      link.href = "https://oss.pdxx.cc/pd/app/app-Q.apk";
      link.download = "app-Q.apk";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downIos() {
      const link = document.createElement("a");
      link.href = "https://oss.pdxx.cc/pd/app/pandaQ.mobileconfig";
      link.download = "pandaQ.mobileconfig";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="less" scoped>
.prefixs {
  background: rgba(32, 44, 55, 1);
  min-height: 100dvh;
  padding-bottom: 20px;
  .headers {
    width: 100%;
    height: 80px;
    background: rgba(32, 44, 55, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 46px;

    .headerLeft {
      width: 173px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headerRight {
      width: 36.7px;
      height: 36.7px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .outBj{
    width: 100%;
    
    background: url('@/assets/prefix/bg.webp') no-repeat;
    background-size: cover;
    background-position: center;
    .mosh{
      margin: 30px auto 0 auto;
      background: url('@/assets/prefix/bgMs.webp') no-repeat;
      background-repeat: no-repeat;
    background-size: revert;
    background-position: center top;
    padding-top: 460px;
    .msTit{
      margin: 0 auto;
      width: 918px;
      height: 92px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
        height: 100%;
      }
    }
    }
  }

  .headImg {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .btnLine {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 92%;
    margin: 0 auto;

    .btnDemo {
      width: 46.3%;
      height: 55px;
      padding: 5px 10px;
      border-radius: 2px;
      background: rgba(0, 231, 1, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btnLeft {
        width: 52px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .shu {
        width: 1px;
        height: 38px;
        background: rgba(7, 24, 36, 1);
      }

      .btnRight {
        color: rgba(7, 24, 36, 1);
        font-family: "Microsoft YaHei UI";
        font-weight: 700;
        font-size: 14px;

        .djxz {
          font-size: 12px;
        }
      }
    }
  }

  .listOut {
    width: 92%;
    margin: 20px auto 0 auto;
    display: inline-flex;
    
    place-content: center;
    place-items: center;
    gap: 22px;
    flex-shrink: 0;
    padding: 10px 4px;
    .pcList{
      width: 224px;
      height: 288px;
      justify-content: center;
      align-items: center;
      background-image: url('@/assets/prefix/beiban.webp');
      background-size: 100%;
      padding:  20px 0;
      cursor: pointer;
      .yuanyu{
        width: 148px;
        height: 148px;
        
        background-size: 100%;
        margin: 0 auto;
        line-height: 148px;
        text-align: center;
        color: rgb(255,255,255,1);
        font-size: 26px;
      }
      .labels{
        line-height: 30px;
        color: rgb(177,186,211,1);
        font-weight: 600;text-align: center;
        background-color: rgb(15,23,42,1);
        border: rgb(51,65,85,1) 2px solid;
        border-radius: 80px;
        width: 86px;
        height: 34px;
        margin: 30px auto 0 auto; 
        font-size: 16px;
        text-align: center;

      }
      .urlDemo{
        font-size: 22px;
        font-weight: 700;
        color: #fff;
        margin: 14px auto 0 auto;
        text-align: center;
      }
    }
    .listDemo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;

      .listLeft {
        width: 240px;

        border-radius: 4px;
        border: 2px solid rgba(47, 69, 83, 1);
        border-left-width: 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .leftLeft {
          width: 60px;
          height: 40px;
          display: flex;
          align-items: center;
          color: #00e701;
          font-family: "Microsoft YaHei UI";
          font-weight: 700;
          font-size: 14px;
          text-align: center;
          justify-content: center;
          background: url("@/assets/prefix/xiej.webp") no-repeat;
          background-size: 100% 100%;
        }

        .leftRight {
          width: calc(100% - 60px - 10px);
          color: rgba(255, 255, 255, 1);
          margin-left: 10px;
          font-family: "Microsoft YaHei UI";
          font-weight: 700;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
      }

      .three {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .inGame {
        width: 64px;
        height: 37px;
        border-radius: 4px;
        background: rgba(0, 231, 1, 1);
        color: rgba(0, 0, 0, 1);
        font-family: "Microsoft YaHei UI";
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .bomImg {
    margin: 40px auto 0 auto;
    width: 160px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bqxx {
    color: rgba(200, 215, 248, 1);
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    width: 92%;
    margin: 20px auto 0 auto;
  }
}
</style>
